var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('ul',{staticClass:"check",class:{ checked: !_vm.upgrade }},[_c('li',{on:{"click":function($event){_vm.getDelete(), (_vm.upgrade = false)}}},[_vm._m(0)])]),_c('div',{staticClass:"_box",class:{ checked: !_vm.upgrade }},[_c('div',{staticClass:"job-card"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.isLogo,"alt":""}})]),_c('div',{staticClass:"job-detail"},[_c('a',{staticClass:"job-title"},[_vm._v(_vm._s(_vm.form.title))]),_c('p',[_vm._v(_vm._s(_vm.isCompany))]),_c('p',[_vm._v(_vm._s(_vm.workingLocate))]),_c('p',[_vm._v(_vm._s(_vm.postingDate))])]),_vm._m(1)])])]),_c('div',{staticClass:"column is-6"},[_vm._l((_vm.tag),function(i,idx){return _c('div',{key:idx,on:{"click":function($event){_vm.getId(i._id), _vm.addEmit()}}},[_c('ul',{staticClass:"check",class:{ checked: _vm.upgrade }},[_c('li',{on:{"click":function($event){_vm.upgrade = true}}},[_vm._m(2,true),_c('span',[_vm._v("( Required 10 Point / Week )")])])])])}),_c('div',{staticClass:"_box",class:{ checked: _vm.upgrade }},[_c('div',{staticClass:"job-card Highlight"},[_vm._m(3),_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.isLogo,"alt":""}})]),_c('div',{staticClass:"job-detail"},[_c('a',{staticClass:"job-title"},[_vm._v(_vm._s(_vm.form.title))]),_c('p',[_vm._v(_vm._s(_vm.isCompany))]),_c('p',[_vm._v(_vm._s(_vm.workingLocate))]),_c('p',[_vm._v(_vm._s(_vm.postingDate))])]),_vm._m(4)])])],2)]),_c('div',{staticClass:"button-group"},[_c('button',{staticClass:"button info",on:{"click":function($event){_vm.addEmit(),
      _vm.$router.push({
        name: 'application',
        query: { id: _vm.$route.query.id, type: _vm.$route.query.type },
      })}}},[_vm._v(" Back ")]),(_vm.$route.query.id &&
      _vm.$route.query.type !== 'draft' &&
      _vm.$route.query.type !== 'expired'
      )?_c('button',{staticClass:"button primary",on:{"click":function($event){return _vm.update()}}},[_vm._v(" Update ")]):_c('button',{staticClass:"button primary",on:{"click":function($event){return _vm.updateDraft()}}},[_vm._v(" Save and Next ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('i',{staticClass:"fa-regular fa-circle-check"}),_vm._v("Classic Job Card")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"save-job-container"},[_c('div',{staticClass:"save-job"},[_c('p',[_c('i',{staticClass:"far fa-star"})]),_c('p',[_vm._v("Save")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('i',{staticClass:"fa-regular fa-circle-check"}),_vm._v("Upgrade Job Post")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"weepwup",attrs:{"data-v-e9c66762":""}},[_c('span',{attrs:{"data-v-e9c66762":""}},[_vm._v("Apply Now")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"save-job-container"},[_c('div',{staticClass:"save-job"},[_c('p',[_c('i',{staticClass:"far fa-star"})]),_c('p',[_vm._v("Save")])])])}]

export { render, staticRenderFns }