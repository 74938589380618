<template>
  <div class="box">
    <div class="columns">
      <div class="column is-6">
        <ul class="check" :class="{ checked: !upgrade }">
          <li @click="getDelete(), (upgrade = false)">
            <p><i class="fa-regular fa-circle-check"></i>Classic Job Card</p>
          </li>
        </ul>
        <div class="_box" :class="{ checked: !upgrade }">
          <div class="job-card">
            <div class="logo">
              <img :src="isLogo" alt="" />
            </div>
            <div class="job-detail">
              <a class="job-title">{{ form.title }}</a>
              <p>{{ isCompany }}</p>
              <p>{{ workingLocate }}</p>
              <p>{{ postingDate }}</p>
            </div>
            <div class="save-job-container">
              <div class="save-job">
                <p><i class="far fa-star"></i></p>
                <p>Save</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div v-for="(i, idx) in tag" :key="idx" @click="getId(i._id), addEmit()">
          <ul class="check" :class="{ checked: upgrade }">
            <li @click="upgrade = true">
              <p><i class="fa-regular fa-circle-check"></i>Upgrade Job Post</p>
              <span>( Required 10 Point / Week )</span>
            </li>
          </ul>
        </div>
        <div class="_box" :class="{ checked: upgrade }">
          <div class="job-card Highlight">
            <p data-v-e9c66762="" class="weepwup">
              <span data-v-e9c66762="">Apply Now</span>
            </p>
            <div class="logo">
              <img :src="isLogo" alt="" />
            </div>
            <div class="job-detail">
              <a class="job-title">{{ form.title }}</a>
              <p>{{ isCompany }}</p>
              <p>{{ workingLocate }}</p>
              <p>{{ postingDate }}</p>
            </div>
            <div class="save-job-container">
              <div class="save-job">
                <p><i class="far fa-star"></i></p>
                <p>Save</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-group">
      <button class="button info" @click="
        addEmit(),
        $router.push({
          name: 'application',
          query: { id: $route.query.id, type: $route.query.type },
        })
        ">
        Back
      </button>
      <!-- :disabled="!whateverActivatesThisLink" :event="whateverActivatesThisLink ? 'click' : ''" -->
      <!-- <button class="button info" v-if="!$route.query.id" @click="addEmit(), $router.push({name: 'application', query: { id: $route.query.id} })">Back</button> -->
      <button v-if="$route.query.id &&
        $route.query.type !== 'draft' &&
        $route.query.type !== 'expired'
        " class="button primary" @click="update()">
        Update
      </button>
      <button v-else class="button primary" @click="updateDraft()">
        Save and Next
      </button>
      <!-- <button class="button primary" v-if="!$route.query.id" @click=" addEmit(), $router.push({name: 'summary', query: { id: $route.query.id}})">Save and Next</button> -->
      <!-- <button @click="addEmit()" class="button primary">Save and Next</button> -->
    </div>
  </div>
</template>

<script>
import { GET_COMPANY_POFILE } from "../../graphql/Company";
import { GET_REUSE } from "@/graphql/Reuse";
import { UPDATE_DRAFT, UPDATE_JOB, GET_JOBID } from "@/graphql/Job";
import apolloClient_Company from "@/services/apollo-client-company";
import moment from "moment";
export default {
  props: ["iget", "getEmail"],
  data: () => ({
    piontget: "",
    whateverActivatesThisLink: true,
    upgrade: false,
    tag: [],
    period: null,
    isLoading: true,
    clickJob: false,
    salaries: [],
    selectedJob: [],
    experiences: [],
    languages: [],
    levels: [],
    jobs: [],
    locations: [],
    getjobId: [],
    form: {
      getTag: [""],
      getPointUpgrade: "",
      getPoints: "",
      file: "",
      period: "",
      companyId: "",
      title: "",
      job: [""],
      location: [""],
      experience: "",
      jobLevelId: "",
      level: "",
      language: [""],
      salary: "",
      salaryid: true,
      description: "",
      tagIds: [],
      zone: [],
      coverletter: false,
      apply: false,
      uselink: false,
      link: "",
      applied: [],
      nortification: [],
      notifyExpire: [],
      ispublished: false,
      postdate: null,
      closedate: "",
      expiredate: "",
      details: "",
      staffQTY: "",
      id: "",
    },
    isLogo: "",
    isCompany: "",
    workingLocate: "",
    postingDate: "",
  }),
  async created() {
    const res = await apolloClient_Company.query({
      query: GET_COMPANY_POFILE,
    });
    this.company = res.data.profile;
    this.piontget = this.company.points.point;
    this.fetchTag();
    if (this.$route.query.id) {
      this.GetJobId();
    }
    if (this.iget) {
      this.form = this.iget;
    }
  },
  methods: {
    async update() {
      try {
        if (this.$route.query.id) {
          await apolloClient_Company.mutate({
            mutation: UPDATE_JOB,
            variables: {
              jobId: this.$route.query.id,
              employerId: this.form.companyId,
              title: this.form.title,
              description: this.form.description,
              jobFunctionId: this.form.job,
              workingLocationId: this.form.location,
              jobExperienceId: this.form.experience,
              jobLanguageId: this.form.language,
              jobEducationLevelId: this.form.level,
              salayRangeId: this.form.salary,
              isShowSalary: this.form.salaryid,
              tagIds: this.form.tagIds,
              jobZoneIds: [],
              jobLevelId: this.form.jobLevelId,
              openingDate: this.form.postdate,
              closingDate: this.form.closedate,
              expiredDate: this.form.expiredate,
              isApplyUrl: false, // this.form.uselink,
              isCoverLetter: false, //this.form.coverletter,
              isNotApply: this.form.apply,
              isHideApplyButton: this.form.apply,
              appliedEmails: this.form.applied,
              nortificationEmails: this.form.nortification,
              notifyExpire: this.form.notifyExpire,
              isPublished: true, //this.form.ispublished,
              image: "", //this.form.file,
              isURL: this.form.link,
              details: "", //this.form.details,
              staffQTY: this.form.staffQTY,
            },
          });
          //  this.$router.back();
          this.$emit("putdata", this.form);
          if (this.$route.query.id) {
            this.$router.push({
              name: "summary",
              query: { id: this.$route.query.id, type: this.$route.query.type },
            });
          }
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    async updateDraft() {
      await apolloClient_Company.query({
        query: UPDATE_DRAFT,
        variables: {
          _id: this.$route.query.id,
          title: this.form.title,
          description: this.form.description,
          jobFunctionId: this.form.job,
          workingLocationId: this.form.location,
          jobExperienceId: this.form.experience,
          jobLanguageId: this.form.language,
          jobEducationLevelId: this.form.level,
          salayRangeId: this.form.salary,
          isShowSalary: this.form.salaryid,
          tagIds: this.form.tagIds,
          jobZoneIds: null, //this.form.zone,
          jobLevelId: this.form.jobLevelId,
          openingDate: this.form.postdate,
          closingDate: this.form.closedate,
          expiredDate: this.form.expiredate,
          isApplyUrl: false, // this.form.uselink,
          isCoverLetter: false, //this.form.coverletter,
          isNotApply: this.form.apply,
          isHideApplyButton: "", //this.form.apply,
          appliedEmails: this.form.applied,
          nortificationEmails: this.form.nortification,
          notifyExpire: this.form.notifyExpire,
          isPublished: true,
          image: "", //this.form.file,
          isURL: this.form.link,
          details: "", //this.form.details,
          staffQTY: this.form.staffQTY,
        },
      });
      this.$emit("putdata", this.form);
      if (this.$route.query.id) {
        this.$router.push({
          name: "summary",
          query: { id: this.$route.query.id, type: this.$route.query.type },
        });
      } else {
        this.$router.push({ name: "summary" });
      }
    },
    async GetJobId() {
      try {
        const res = await apolloClient_Company.query({
          query: GET_JOBID,
          variables: {
            type: this.$route.query.type,
            jobId: this.$route.query.id,
          },
        });
        this.isLoading = false;
        this.getjobId = res.data.editJob;
        const mapName = (arr) => arr.map((i) => i.name);
        this.workingLocate = mapName(this.getjobId.workingLocationId).join(
          ", "
        );
        this.isLogo = this.$urlImageShow + this.getjobId.employerId.logo;
        this.isCompany = this.getjobId.employerId
          ? this.getjobId.employerId.companyName
          : "";
        this.postingDate =
          moment(this.getjobId.openingDate).format("DD MMM YYYY") +
          " - " +
          moment(this.getjobId.closingDate).format("DD MMM YYYY");
        this.form.job = this.getjobId.jobFunctionId.map((i) => i._id);
        this.form.language = this.getjobId.jobLanguageId.map((i) => i._id);
        this.form.location = this.getjobId.workingLocationId.map((i) => i._id);
        this.form.experience = this.getjobId.jobExperienceId._id;
        this.form.tagIds =
          this.getjobId.tagIds !== null
            ? this.getjobId.tagIds.map((i) => i._id)
            : null;
        this.form.jobLevelId = this.getjobId.jobLevelId._id;
        this.form.level = this.getjobId.jobEducationLevelId._id;
        this.form.salary = this.getjobId.salayRangeId._id;
        this.form.title = this.getjobId.title;
        this.form.apply = this.getjobId.isNotApply;
        this.form.link = this.getjobId.isURL;
        this.form.description = this.getjobId.description;
        this.form.salaryid = this.getjobId.isShowSalary;
        this.form.applied = this.getjobId.appliedEmails
          ? this.getjobId.appliedEmails
          : [];
        this.form.nortification = this.getjobId.nortificationEmails
          ? this.getjobId.nortificationEmails
          : [];
        this.form.notifyExpire = this.getjobId.notifyExpire
          ? this.getjobId.notifyExpire
          : [];
        this.form.postdate = new Date(this.getjobId.openingDate);
        this.form.closedate = new Date(this.getjobId.closingDate);
        this.form.expiredate = new Date(this.getjobId.expiredDate);
        this.form.companyId = this.getjobId.employerId._id;
        this.form.staffQTY = this.getjobId.staffQTY;
        this.d1 = moment(this.form.postdate);
        this.d2 = moment(this.form.expiredate);
        this.days = 24 * 60 * 60 * 1000 * 1;
        this.difference_ms = Math.abs(this.d1 - this.d2);
        this.getDays = Math.floor(this.difference_ms / this.days) + 1;
        this.form.period = Math.floor(this.getDays / 7);

        if (this.form.tagIds.map((i) => i._id).length) {
          this.upgrade = true;
          this.form.getPointUpgrade = this.form.period * 10;
          this.form.getPoints =
            this.form.period * 20 + this.form.getPointUpgrade;
          this.points = this.piontget - this.form.getPoints;
          this.whateverActivatesThisLink = false;
          if (this.form.getPoints > this.piontget) {
            const isConfirmed = this.$dialog.alert(
              "Point not enough " + this.points
            );
            if (!isConfirmed) return;
          }
        }
      } catch { }
    },
    addEmit() {
      this.$emit("putdata", this.form);
    },
    getDelete() {
      this.form.tagIds = [];
      if (this.form.getPointUpgrade) {
        this.form.getPointUpgrade = "";
        this.form.getPoints = this.form.getPoints - this.form.period * 10;
        this.whateverActivatesThisLink = true;
      }
    },
    getId(id) {
      this.form.tagIds = id;
      if (!this.form.getPointUpgrade) {
        this.form.getPointUpgrade = this.form.period * 10;
        this.form.getPoints = this.form.period * 20 + this.form.getPointUpgrade;
      }
      if (this.iget.getPoints > this.piontget) {
        this.points = this.iget.getPoints - this.piontget;
        this.whateverActivatesThisLink = false;
        const isConfirmed = this.$dialog.alert(
          "Point not enough" + this.points
        );
        if (!isConfirmed) return;
      } else {
        this.whateverActivatesThisLink = true;
      }
    },
    async fetchTag() {
      const res = await apolloClient_Company.query({
        query: GET_REUSE,
        variables: {
          type: "Tag",
          page: 1,
          perPage: 1000,
        },
      });
      this.tag = res.data.getReuse.reuseType;
      this.form.id = this.tag.filter((i) => i._id);
    },
  },
};
</script>

<style lang="scss" scoped>
.check {
  display: inline-block;
  cursor: pointer;
  color: #000;
  margin-bottom: 10px;

  &.checked {
    position: relative;

    p {
      color: var(--primary-color);
      background-color: rgba(var(--primary-color-rgb), 0.15);

      i {
        font-weight: 700;
      }
    }
  }

  li {
    display: flex;
    align-items: center;

    p {
      border-radius: 5px;
      padding: 10px 20px;
      background-color: var(--grey-color);
      margin: 0;
      cursor: pointer;
      font-size: var(--sm-font);
      font-weight: 700;
      margin-right: 10px;

      i {
        margin-right: 10px;
        font-size: var(--sm-font);
      }
    }

    span {
      color: var(--dark-grey-color);
      font-size: var(--sm-font);
      font-weight: normal;
    }
  }
}

._box {
  position: relative;
  border-radius: 5px;
  padding: 30px 20px;
  border: 1px solid var(--border-color);
  background-color: var(--grey-color);
  transition: all ease-in-out 0.2s;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(#000, 0.1);
    backdrop-filter: blur(1px);
  }

  &.checked {
    border-color: var(--border-color);
    background-color: #f7f8fb;

    &::after {
      display: none;
    }
  }
}

.job-card {
  background-color: #fff;
  padding: 20px;
  font-size: 14px;
  color: var(--dark-grey-color);
  display: flex;
  border: 1px solid var(--border-color);

  @include mobile {
    padding: 10px;
  }

  .logo {
    display: flex;
    align-items: center;
    margin-right: 15px;
    justify-content: center;
    max-width: 80px;
    max-height: 80px;
    min-width: 80px;
    min-height: 80px;

    @include mobile {
      margin-right: 10px;
    }

    @include fullhd {
      max-width: 100px;
      max-height: 100px;
      min-width: 100px;
      min-height: 100px;
      margin-right: 20px;
    }

    img {
      width: 100%;
    }
  }

  .job-detail {
    flex-grow: 1;
    overflow: hidden;
    line-height: 1.5;
  }

  .job-title {
    cursor: pointer;
    color: var(--text-color);
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;

    @include fullhd {
      font-size: var(--sm-font);
    }

    &:hover {
      color: var(--primary-color);
    }
  }

  p {
    color: var(--dark-grey-color);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: var(--sm-font);

    @include mobile {
      line-height: 1.5;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .save-job-container {
    display: flex;
  }

  .save-job {
    cursor: pointer;
    margin-left: auto;
    min-width: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mobile {
      min-width: 30px;
    }

    i {
      font-size: 16px;
      color: var(--primary-color);
    }
  }

  &.small {
    border-left: 0;
    border-right: 0;
    padding: 10px 0;
    border-top: 0;

    &:first-child {
      padding-top: 0 !important;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

//job-card

.job-card.Highlight {
  border: 1px solid var(--danger-color) !important;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(#000, 0.15);

  .job-title {
    font-weight: 700;
    color: var(--danger-color);

    &:hover {
      color: var(--danger-color);
    }
  }

  .weepwup {
    display: block;
    background-color: var(--danger-color);
    color: #fff;
    padding: 3px 8px 3px 8px;
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 0 0 5px 0;
    font-size: 12px;

    @include mobile {
      font-size: 10px;
    }

    span {
      animation: wipwup 0.8s infinite;
    }
  }

  @keyframes wipwup {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
</style>
